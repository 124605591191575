.dashboard-survey-page__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap-reverse;
}

.dashboard-survey-page {
	overflow: auto;
}

.dashboard-survey-page .ant-table-content {
	overflow: auto;
}