.list-add-card {
	width: 100%;
	height: 188px;
	color: rgba(0, 0, 0, .45);
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	border-style: dashed;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.list-add-card:hover {
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, .16), 0 3px 6px 0 rgba(0, 0, 0, .12), 0 5px 12px 4px rgba(0, 0, 0, .09);
}

.list-add-card svg {
	font-size: 30px;
	color: #d9d9d9;
}