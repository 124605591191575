.api-table th {
	background: #001429bd !important;
	color: white !important;
}

.api-table th:hover {
	background: #0014298f !important;
}

.ant-table-thead th:hover th.ant-table-column-has-sorters .ant-table-filter-trigger-container {
	background: #01162b00 !important;
	color: white !important;
}

.ant-table-thead th.ant-table-column-has-sorters .ant-table-filter-trigger-container {
	background: #01162b00 !important;
	color: white !important;
}

.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
	color: white !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
	color: white !important;
}

.ant-table-column-sorters {
	display: flex;
	justify-content: center;
}

.ant-pagination-item {
	border-color: #001429bd !important;
	color: #001429bd !important;
}

.ant-pagination-item a {
	color: #001429bd !important;
}