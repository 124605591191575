.drawer-answer-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.drawer-answer-item__icon {
	margin: 0 5px;
	transform: scale(1.2)
}

.question-drawer .question-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
}

.question-drawer .answer-container {
	margin-bottom: 50px;
}

.question-drawer .btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-drawer .btn-container button {
	width: 300px;
}

.drawer-answer-item__answer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.drawer-answer-item__image {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.drawer-answer-item__check {
	width: 30%;
	display: flex;
	justify-content: center;
}

.drawer-answer-item__image .ant-typography {
	margin-right: 10px;
}

.question-drawer__dependency-button {
	width: 90px;
	margin-top: 10px;
	padding: 0px !important;
}

.answer-copy__btn {
	margin-bottom: 10px;
	margin-right: 10px;
}

.question-drawer .sub-question-container {
	margin-bottom: 50px;
}

.question-drawer .drawer-sub-question-item__sub-question {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-drawer .drawer-sub-question-item {
	display: flex;
	justify-content: center;
	align-items: center;
}

.question-drawer .drawer-sub-question-item__icon {
	margin: 0 5px;
	transform: scale(1.2)
}