.vote-selector {
	display: flex;
}

.vote-selector__field {
	width: 250px;
}

.vote-selector__label {
	width: 65px;
	display: inline-block;
}

.vote-select {
	width: 150px;
	margin-left: 10px !important;
	margin-top: 10px !important;
}