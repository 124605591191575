@import '~antd/dist/antd.css';

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	outline: none !important;
}

#root,
.App {
	height: 100%;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

.ant-layout-content {
	overflow: auto;
}

@media (max-width: 640px) {
	.ant-layout-content {
		margin: 0px !important;
		padding: 5px !important;
	}
}